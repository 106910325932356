<template>
    <div>
        <div id="app-flip" :class="{ 'has-mouse': hasMouse }" @touchstart="hasMouse = false">
            <Flipbook class="flipbook" :start-page="pageNum" :pages="pages" :single-page="true"
                      :pages-hi-res="pages" v-slot="flipbook" ref="flipbook"
                      @flip-left-start="onFlipLeftStart" @flip-left-end="onFlipLeftEnd"
                      @flip-right-start="onFlipRightStart" @flip-right-end="onFlipRightEnd">
                <div class="action-bar">
                    <span class="btn">
                    <fa icon="arrow-left" :class="{ disabled: !flipbook.canFlipLeft }"
                        @click="flipbook.flipLeft"/>
                    </span>
                    <span class="btn">
                    <fa icon="plus" :class="{ disabled: !flipbook.canZoomIn }"
                        @click="flipbook.zoomIn"/>
                    </span>
                    <span class="page-num">
                      {{ i18n('Page') }} {{ flipbook.page }}
                        {{ i18n('of') }} {{ flipbook.numPages }}
                    </span>
                    <span class="btn">
                    <fa icon="minus" :class="{ disabled: !flipbook.canZoomOut }"
                        @click="flipbook.zoomOut"/>
                    </span>
                    <span class="btn">
                    <fa icon="arrow-right" :class="{ disabled: !flipbook.canFlipRight }"
                        @click="flipbook.flipRight"/>
                    </span>
                </div>
            </Flipbook>
        </div>
    </div>
</template>

<script>
import Flipbook from 'flipbook-vue';
import { FontAwesomeIcon as Fa } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faArrowLeft, faArrowRight, faPlus, faMinus,
} from '@fortawesome/free-solid-svg-icons';

library.add(faArrowLeft, faArrowRight, faPlus, faMinus);
export default {
    name: 'UserIndex',
    components: {
        Flipbook, Fa,
    },
    inject: ['http', 'route', 'i18n'],
    // eslint-disable-next-line vue/require-prop-types
    props: ['pages'],
    data() {
        return {
            bookMagazineImages: [],
        };
    },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/enso.scss';

.image-news-details {
    object-fit: cover;
}

.title-news {
    overflow: hidden;
    text-align: center;
    font-size: 20px !important;
}

.title-h1 {
    font-size: 30px;
    color: $primary;
    text-align: center;
}

.date {
    font-size: 14px;
    display: flex;
    justify-content: center;
}

.pdf-container {
    width: 85vw;
    height: 85vh;
    margin: auto;
}

.pdf-container iframe {
    width: 100%;
    height: 100%;
}

#app-flip {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #333;
    color: $greyCcc;
    overflow: hidden;
}

a {
    color: inherit;
}

.action-bar {
    width: 100%;
    height: 30px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.action-bar .btn {
    font-size: 30px;
    color: $darkDarkGrey;
}

.action-bar .btn svg {
    bottom: 0;
}

.action-bar .btn:not(:first-child) {
    margin-left: 10px;
}

.has-mouse .action-bar .btn:hover {
    color: $greyCcc;
    filter: drop-shadow(1px 1px 5px #000);
    cursor: pointer;
    height: 100%;
}

.action-bar .btn:active {
    filter: none !important;
}

.action-bar .btn.disabled {
    color: #666;
    pointer-events: none;
}

.action-bar .page-num {
    font-size: 12px;
    margin-left: 10px;
}

.flipbook .bounding-box {
    box-shadow: 0 0 20px #000;
}
.flipbook{
    width: 90vw;
    height: calc(90vh - 50px - 40px);
}

.page {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flipbook .viewport {
    width: 90vw;
    height: calc(100vh - 50px - 40px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.flipbook-container {
    position: relative;
    height: 100%;
    background-color: #333;
}

.flipbook-container .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: $greyCcc;
}
</style>
